import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text'
import Loader from 'components/Loader/Loading';
import { FontWeight } from 'constant/ui';

const Type = {
    light: 'text-gray-500 bg-transparent-gray-400-30 min-w-17.5',
    dark: 'text-gray-500 bg-transparent-gray-500-30 min-w-17.5',
    danger: 'text-semantic-error_base bg-semantic-error_light min-w-17.5',
    success: 'text-semantic-success_base bg-semantic-success_light border border-semantic-success_base',
    primary: 'text-basic-white bg-primary1-500 rounded-full min-w-17.5',
    secondary: 'text-semantic-warning_base bg-semantic-warning_light border-semantic-warning_base rounded-sm-0.5 min-w-17.5 border',
    yellow: 'text-gray-600 bg-semantic-warning_light_secondary rounded-sm-0.5 min-w-17.5',
    outline_yellow: "text-other-yellow-900 bg-other-yellow-400 rounded-sm-0.5 min-w-17.5"
}

const Badge = ({ className, totalBadge, children, type, badgeContent, isLoading, fontWeight, ...rest }) => {

    return (typeof badgeContent === 'number')
        ? <div className='relative inline-block'>
            {children}
            {(!!badgeContent || isLoading) && <>
                <span className={`${isLoading ? 'p-0.5' : 'px-1'}  absolute left-[calc(100%_-_12px)] lg:left-full-minus-6 -top-1.5 h-4 min-w-[16px] rounded-full bg-primary1-500 font-Lato text-basic-white text-xs leading-4 font-semibold z-10`}>
                    <div className='relative'>
                        {isLoading
                            ? <Loader className='spinner--small' type='spinner'
                                spinnerColor='white' size={12} strokeWidth={8} />
                            : badgeContent > 99 ? '99+' : badgeContent}
                    </div>
                </span>
            </>
            }
        </div>
        : <Text className={`${className} ${children ? 'badge--label' : ''} inline-flex items-center justify-center min-h-[24px] leading-3 min-w-15 ${FontWeight[fontWeight] || FontWeight[600]} ${Type[type]} badge`}
            type='caption' {...rest} >{children ? children : (totalBadge > 0 ? totalBadge : 0) + '+'}</Text>
}

Badge.defaultProps = {
    className: '',
    type: 'light'
}

Badge.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    totalBadge: PropTypes.number,
    type: PropTypes.oneOf(['light', 'dark', 'danger', 'primary', 'success']),
    badgeContent: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]),
}

export default Badge;
