import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import { getAuth } from 'services/identity.service';
import LinkAnchor from './LinkAnchor'
import IconAngleRight from './SvgIcons/IconAngleRight.svg'
const NonLoggedinMessage = ({ className, pdpPath }) => {
    const [checkCategory, setCheckCategory] = useState('')
    const router = useRouter();
    const auth = getAuth();
    const checkAsPath = router?.asPath;

    useEffect(() => {
        if (checkAsPath.includes('/medicines/')) {
            setCheckCategory(checkAsPath)
        }
    }, [router?.asPath])

    return (
        <div className={`${className}`}>
            {!auth
                ? ['/medicines/allMedicines', '/offers', `${pdpPath}`, `${checkCategory}`].includes(checkAsPath) && <div className={`flex items-center justify-center h-11 w-full overflow-hidden relative bg-other-yellow-400 text-2xl font-semibold text-gray-900`}>
                    <div className="w-fit justify-center flex relative text-base" aria-hidden="true">
                        Signup to get best discount<LinkAnchor href='/sign-up-practitioner' className={"ml-2 text-primary1-500"}>Signup now<IconAngleRight className='h-5 inline-block stroke-primary1-500'></IconAngleRight></LinkAnchor>
                    </div>
                </div>
                : null
            }
        </div>
    )
}

export default NonLoggedinMessage