import React from 'react';
import PropTypes from 'prop-types'

import IconImage from '../atoms/IconImage';
import Text from '../atoms/Text';
import SvgIcon from '../atoms/SvgIcon';
import Badge from './../atoms/Badge';

const IconWithLabel = ({
    iconType,
    imageSrc,
    iconContainerClass,
    containerClass,
    iconClass,
    labelClass,
    label,
    onClick,
    imageWidth,
    imageHeight,
    textType = 'body',
    anchorRef,
    badgeContent,
    dangerouslySetInnerHTML,
    dataAutomation,
    ...rest
}) => {
    return (
        <div className={`${containerClass} iconWithLabel  ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick} ref={anchorRef} data-automation={dataAutomation} {...rest}>
            <div className={iconContainerClass}>
                {
                    imageSrc
                        ? <IconImage className={iconClass} imageWidth={imageWidth} imageHeight={imageHeight} alterText={label} IconSrc={imageSrc} alt={label}/>
                        : <Badge type='primary' badgeContent={badgeContent}><SvgIcon className={iconClass} type={iconType} /></Badge>
                }
            </div>
            {label && <Text className={labelClass} type={textType} >{label}</Text>}
            {dangerouslySetInnerHTML && <div className={labelClass} type={textType} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />}
        </div>
    )
}

IconWithLabel.defaultProps = {
    badgeContent: 0
}

IconWithLabel.propTypes = {
    iconType: PropTypes.string,
    imageSrc: PropTypes.string,
    iconContainerClass: PropTypes.string,
    iconClass: PropTypes.string,
    labelClass: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    direction: PropTypes.oneOf(['vertical', 'horizontal']),
    badgeContent: PropTypes.number.isRequired,
};

export default IconWithLabel;
