import React from 'react'
import PropTypes from 'prop-types';

const CardBody = ({ className, children, ...rest }) => {
    return (
        <div className={`${className} cardBody`} {...rest}>
            {children}
        </div>
    )
}

CardBody.defaultProps = {
    className: '',
}

CardBody.propTypes = {
    className: PropTypes.string,
}

export default CardBody;
