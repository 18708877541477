import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';
import IconChevronDownThin from './SvgIcons/IconChevronDownThin.svg';
import IconChevronDown from './SvgIcons/IconChevronDown.svg';
import IconMinus from './SvgIcons/IconMinus.svg';
import IconPlus from './SvgIcons/IconPlus.svg';
import IconArrowDropDownCircle from './SvgIcons/IconArrowDropDownCircle.svg';

export const useRefDimensions = (ref, children) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })
    React.useEffect(() => {
        if (ref.current) {
            const { current } = ref
            const boundingRect = current.getBoundingClientRect()
            const { width, height } = boundingRect
            setDimensions({ width: Math.round(width), height: Math.round(height), windowWidth: window.outerWidth, windowHeight: window.outerHeight })
        }
    }, [ref, children])
    return dimensions
}

const CATEGORY = {
    DEFAULT: { color: 'text-primary2-500', iconColor: 'text-primary2-500' },
    IRON: { color: 'text-other-red-500', iconColor: 'text-other-red-500' },
    SILVER: { color: 'text-other-gray-500', iconColor: 'text-other-gray-500' },
    GOLD: { color: 'text-other-yellow-500', iconColor: 'text-other-yellow-500' },
    DIAMOND: { color: 'text-other-cerulean-500', iconColor: 'text-other-cerulean-500' },
    PLATINUM: { color: 'text-other-purple-500', iconColor: 'text-other-purple-500' },
}

const TYPE = {
    CHEVRON_BOLD: { iconName: IconChevronDownThin, color: 'text-primary1-500', iconColor: 'stroke-gray-600', weight: 'font-bold' },
    BORER_CHEVRON: { iconName: IconChevronDownThin, color: 'text-gray-400', iconColor: 'stroke-gray-600', weight: 'font-semibold', fontSize: 'text-14-20', header: 'border-gray-200 border bg-basic-white rounded-none', isShowTopBorder: true, iconSize: 16 },
    ARROW_DOWN_CIRCLE: { iconName: IconArrowDropDownCircle , color: 'text-gray-900', iconColor: 'stroke-gray-600', weight: 'font-bold', isShowTopBorder: true },
    TRANSPARENT: { iconName: IconPlus, activeIconName: IconMinus, color: 'text-gray-400', iconColor: 'stroke-gray-600', weight: 'font-semibold', fontSize: 'text-16-18', header: 'bg-transparent-0', isBottomTopBorder: true, headerWrapper: 'pl-0.5 pr-0.5 py-3' },
    CHEVRON_NORMAL: { iconName: IconChevronDownThin, color: 'text-primary1-500', iconColor: 'stroke-gray-600', weight: 'font-semibold', iconSize: 16 },
}

// Accordion Children
const AccordionItem = ({ className = '', contentClass = '', heading, children, onClick = () => { }, defaultActive = false, dataAutomation, color, type, rounded, pt, pb, px = 'px-4', titleClass='', isSharedLink }) => {
    const [active, setActive] = useState(defaultActive);
    const itemRef = useRef(null);
    const [dimensions, setDimensions] = useState(useRefDimensions(itemRef, children))
    useEffect(() => {
        setActive(defaultActive)
    }, [defaultActive])

    const Icon = (TYPE[type]?.activeIconName && active) ? TYPE[type]?.activeIconName : TYPE[type]?.iconName;

    useEffect(() => {
        if (itemRef.current) {
            const resizeObserver = new ResizeObserver(entries =>
                setDimensions({ ...dimensions, height: entries[0].target.clientHeight })
            )
            resizeObserver.observe(itemRef.current)
        }
    }, [itemRef.current])

    return (
        <div className={`${className} ${rounded} ${isSharedLink ? '' : TYPE[type]?.fontSize ? TYPE[type]?.header : 'shadow-sm bg-basic-white'} text-base mb-2 relative`}>
            {TYPE[type]?.isShowTopBorder && active && <div className='w-[calc(100%_-_4px)] left-0.5 mt-[1px] mx-auto h-0.5 bg-primary1-400 absolute'></div>}
            <div className={`${TYPE[type]?.headerWrapper || 'pl-4 pr-4.5 sm:pr-5 py-3'} flex justify-between items-center select-none cursor-pointer`} onClick={() => { onClick(); setActive(!active) }} data-automation={`${dataAutomation}-${active ? 'open' : 'close'}`}>
                <div className={`${active ? color || CATEGORY[color]?.color || 'text-primary1-500' : 'text-gray-900'} ${TYPE[type] ? TYPE[type].weight : color ? 'font-semibold' : ''} ${TYPE[type]?.fontSize ? TYPE[type]?.fontSize : ''} ${titleClass}`}> {heading} </div>
                <div className="w-4 md:w-5 h-5 flex justify-center flex-shrink-0">
                    {TYPE[type]
                        ? <Icon color={(TYPE[type]?.header && active) ? 'text-primary1-400 absolute' : 'text-gray-900'} size={TYPE[type]?.iconSize || 24} style={{ transition: 'all .2s ease', transform: active ? 'rotate(180deg)' : null, width: TYPE[type]?.iconSize || 24 }} />
                        : color
                            ? <IconChevronDown className={  active ? CATEGORY[color].iconColor : 'text-gray-900'} size={15} style={{ transition: 'all .2s ease', transform: active ? 'rotate(180deg)' : null, width: 15 }} />
                            : active
                                ? <IconMinus color={'text-gray-600'} size={24} style={{ transition: 'all .2s ease', transform: 'rotate(180deg)'}} />
                                : <IconPlus className='text-gray-600' size={24} style={{ transition: 'all .2s ease', transform: null }} />}

                </div>
            </div>

            <div className={`${active ? `opacity-100` : 'h-00 opacity-0'} transition-all duration-500 overflow-hidden`} style={{ height: dimensions.height }}>
                <div ref={itemRef} className={`${contentClass} ${px} ${pb} ${color ? '' : pt}`}>
                    {children}
                </div>
            </div>
            {TYPE[type]?.isBottomTopBorder && <div className='w-[calc(100%_-_4px)] left-0.5 mt-[1px] mx-auto h-[1px] bg-gray-300 absolute'></div>}
        </div>
    )
}
AccordionItem.defaultProps = {
    defaultActive: false,
    dataAutomation: '',
    color: null,
    rounded: 'rounded-lg',
    pt: 'pt-2',
    pb: 'pb-5',
}

AccordionItem.propTypes = {
    heading: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['IRON', 'SILVER', 'GOLD', 'DIAMOND', 'PLATINUM']),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    defaultActive: PropTypes.bool,
    dataAutomation: PropTypes.string,
    rounded: PropTypes.string,
    pt: PropTypes.string,
    pb: PropTypes.string,
};

export default AccordionItem;