import React from 'react'

const classes = {
    bodyType1: 'px-3.5 sm:px-8 pt-4 sm:pt-6',
    bodyType2: 'px-3.5 sm:px-8 pt-4 sm:pt-6'
}

const BodyForModal = ({ className, children, type = 'bodyType1' }) => {
    return (
        <div className={`${className} modal__body`}>{children}</div>
    )
}

export default BodyForModal
