import React, { useLayoutEffect, useState } from 'react';

const useWindowSize = ({ specificEl = {}, isDocumentHeight = null } = {}) => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([
                specificEl?.current ? specificEl.current.clientWidth : window.innerWidth,
                specificEl?.current ? specificEl.current.clientHeight : window.innerHeight
            ]);
        }
        function updateDocSize() {
            var body = document.body,
                html = document.documentElement;

            var width = Math.max(body.scrollWidth, body.offsetWidth,
                html.clientWidth, html.scrollWidth, html.offsetWidth);

            var height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);

            setSize([width, height]);
        }
        window.addEventListener('resize', isDocumentHeight ? updateDocSize : updateSize);
        isDocumentHeight ? updateDocSize() : updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

export default useWindowSize
