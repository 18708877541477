import PropTypes from 'prop-types';
import React from 'react'

import Heading from './../../atoms/Heading'
import InputSearch from './../../atoms/InputSearch';
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import Alert from 'molecules/Alert';

const HeaderForModal = ({ className, searchText, setSearchText, children, isCloseButton, isSearchBar, handlerModalClose, onChange, dataAutomation, dataAutomationForSearch, padding, alertMsg, setAlertMsg }) => {
    return (
        <>
            <div className={`${className} ${padding || 'px-3.5 xs:px-5 sm:px-8'} flex gap-5 justify-between items-center border-b border-gray-200 min-h-[60px] relative bg-basic-white z-10`}>
                <Heading type="h3" className='text-lg.5 my-2 line-clamp-2' dataAutomation={dataAutomation}>{children}</Heading>


                <div className='flex gap-5 xs:gap-13.5'>
                    {isSearchBar && <InputSearch type='small' className='max-w-200px ' placeholder="Search" searchText={searchText} onChange={onChange} dataAutomation={dataAutomationForSearch} />}
                    {
                        isCloseButton && (
                            <button title='Close' type='reset' onClick={handlerModalClose} className='group p-1' {...(dataAutomation && { 'data-automation': dataAutomation + '-modal-close' })}>
                                <IconClose className="w-6 fill-none stroke-gray-900 group-hover:stroke-primary1-500" />
                            </button>
                        )
                    }
                </div>

            </div>
            {alertMsg?.message && <Alert className='top-15 z-9' type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
        </>

    )
}

HeaderForModal.defaultProps = {
    className: '',
    padding: null,
}

HeaderForModal.propTypes = {
    isCloseButton: PropTypes.bool,
    handlerModalClose: PropTypes.func,
    padding: PropTypes.string,
}

export default HeaderForModal