import dynamic from 'next/dynamic';

const SvgIcon = ({ className, type, isSizeForHeight, size, dataAutomation, color, onClick }) => {
    const loading = () => <div style={{ width: size, height: size }}></div>
    const Icon = dynamic(() => import(`./SvgIcons/${type}.svg`), { loading })

    return <Icon type={type} className={className} isSizeForHeight={isSizeForHeight} size={size} dataAutomation={dataAutomation} color={color} onClick={onClick}/>
}

export const Svg = ({ className, type, viewBox, isSizeForHeight, size, dataAutomation, onClick, children, color, ...rest }) => {
    return (
        <svg viewBox={viewBox} data-type={type}
            className={`${className} ${onClick ? 'cursor-pointer' : ''} ${color} shrink-0`}
            style={{ ...(isSizeForHeight ? { height: size } : { width: size }) }}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            {...(dataAutomation && { 'data-automation': dataAutomation })} {...rest}
        >
            {children}
        </svg>
    )
}

export default SvgIcon;