export const WEIGHT = {
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold',
}

export const DIRECTION = {
    LEFT: 'flex flex-row',
    RIGHT: 'flex flex-row-reverse',
    TOP: 'flex flex-col',
    BOTTOM: 'flex flex-col flex-col-reverse',
}

export const DIRECTION_MD = {
    LEFT: 'md:flex-row',
    RIGHT: 'md:flex-row-reverse',
    TOP: 'md:flex-col',
    BOTTOM: 'md:flex-col md:flex-col-reverse',
}

const FONT_SIZE = {
    '12-16': 'text-12-16',
    '12-18': 'text-12-18',
    '14-16': 'text-14-16',
    '14-18': 'text-14-18',
    '14-22': 'text-14-22',
    '14-20': 'text-14-20',
    '16-18': 'text-16-18',
    '16-20': 'text-16-20',
    '16-24': 'text-16-24',
    '16-28': 'text-16-28',
    '18-28': 'text-18-28',
    '18-20': 'text-18-20',
    '20-24': 'text-20-24',
    '22-24': 'text-22-24',
    '22-28': 'text-22-28',
    '24-24': 'text-24-24',
}

export const CLR = {
    // Basic Colors
    white: { text: 'text-basic-white', bg: 'bg-basic-white', textGroupHover: 'group-hover:text-basic-white' },
    black: { text: 'text-basic-black', bg: 'bg-basic-black', textGroupHover: 'group-hover:text-basic-black' },
    blackPure: { text: 'text-basic-blackPure', bg: 'bg-basic-blackPure', textGroupHover: 'group-hover:text-basic-blackPure' },
    whitePure: { text: 'text-basic-whitePure', bg: 'bg-basic-whitePure', textGroupHover: 'group-hover:text-basic-whitePure' },
    blueDark: { text: 'text-basic-blueDark', bg: 'bg-basic-blueDark', textGroupHover: 'group-hover:text-basic-blueDark' },

    // Primary Colors
    primary50: { text: 'text-primary1-50', bg: 'bg-primary1-50', textGroupHover: 'group-hover:text-primary1-50' },
    primary100: { text: 'text-primary1-100', bg: 'bg-primary1-100', textGroupHover: 'group-hover:text-primary1-100' },
    primary200: { text: 'text-primary1-200', bg: 'bg-primary1-200', textGroupHover: 'group-hover:text-primary1-200' },
    primary300: { text: 'text-primary1-300', bg: 'bg-primary1-300', textGroupHover: 'group-hover:text-primary1-300' },
    primary400: { text: 'text-primary1-400', bg: 'bg-primary1-400', textGroupHover: 'group-hover:text-primary1-400' },
    primary500: { text: 'text-primary1-500', bg: 'bg-primary1-500', textGroupHover: 'group-hover:text-primary1-500' },
    primary600: { text: 'text-primary1-600', bg: 'bg-primary1-600', textGroupHover: 'group-hover:text-primary1-600' },
    primary700: { text: 'text-primary1-700', bg: 'bg-primary1-700', textGroupHover: 'group-hover:text-primary1-700' },
    primary800: { text: 'text-primary1-800', bg: 'bg-primary1-800', textGroupHover: 'group-hover:text-primary1-800' },
    primary900: { text: 'text-primary1-900', bg: 'bg-primary1-900', textGroupHover: 'group-hover:text-primary1-900' },

    // Secondary Colors
    secondary50: { text: 'text-secondary-50', bg: 'bg-secondary-50', textGroupHover: 'group-hover:text-secondary-50' },
    secondary100: { text: 'text-secondary-100', bg: 'bg-secondary-100', textGroupHover: 'group-hover:text-secondary-100' },
    secondary200: { text: 'text-secondary-200', bg: 'bg-secondary-200', textGroupHover: 'group-hover:text-secondary-200' },
    secondary300: { text: 'text-secondary-300', bg: 'bg-secondary-300', textGroupHover: 'group-hover:text-secondary-300' },
    secondary400: { text: 'text-secondary-400', bg: 'bg-secondary-400', textGroupHover: 'group-hover:text-secondary-400' },
    secondary500: { text: 'text-secondary-500', bg: 'bg-secondary-500', textGroupHover: 'group-hover:text-secondary-500' },
    secondary600: { text: 'text-secondary-600', bg: 'bg-secondary-600', textGroupHover: 'group-hover:text-secondary-600' },
    secondary700: { text: 'text-secondary-700', bg: 'bg-secondary-700', textGroupHover: 'group-hover:text-secondary-700' },
    secondary800: { text: 'text-secondary-800', bg: 'bg-secondary-800', textGroupHover: 'group-hover:text-secondary-800' },
    secondary900: { text: 'text-secondary-900', bg: 'bg-secondary-900', textGroupHover: 'group-hover:text-secondary-900' },

    // Gray Colors
    gray50: { text: 'text-gray-50', bg: 'bg-gray-50', textGroupHover: 'group-hover:text-gray-50' },
    gray100: { text: 'text-gray-100', bg: 'bg-gray-100', textGroupHover: 'group-hover:text-gray-100' },
    gray200: { text: 'text-gray-200', bg: 'bg-gray-200', textGroupHover: 'group-hover:text-gray-200' },
    gray300: { text: 'text-gray-300', bg: 'bg-gray-300', textGroupHover: 'group-hover:text-gray-300' },
    gray400: { text: 'text-gray-400', bg: 'bg-gray-400', textGroupHover: 'group-hover:text-gray-400' },
    gray500: { text: 'text-gray-500', bg: 'bg-gray-500', textGroupHover: 'group-hover:text-gray-500' },
    gray600: { text: 'text-gray-600', bg: 'bg-gray-600', textGroupHover: 'group-hover:text-gray-600' },
    gray700: { text: 'text-gray-700', bg: 'bg-gray-700', textGroupHover: 'group-hover:text-gray-700' },
    gray800: { text: 'text-gray-800', bg: 'bg-gray-800', textGroupHover: 'group-hover:text-gray-800' },
    gray900: { text: 'text-gray-900', bg: 'bg-gray-900', textGroupHover: 'group-hover:text-gray-900' },

    // Neutral Color
    neutral3: { text: 'text-neutral-3', bg: 'bg-neutral-3', textGroupHover: 'group-hover:text-neutral-3' },
    neutral5: { text: 'text-neutral-5', bg: 'bg-neutral-5', textGroupHover: 'group-hover:text-neutral-5' },
    neutral6: { text: 'text-neutral-6', bg: 'bg-neutral-6', textGroupHover: 'group-hover:text-neutral-6' },

    // Semantic Colors
    semanticInfoLight: { text: 'text-semantic-info_light', bg: 'bg-semantic-info_light', textGroupHover: 'group-hover:text-semantic-info_light' },
    semanticInfoBase: { text: 'text-semantic-info_base', bg: 'bg-semantic-info_base', textGroupHover: 'group-hover:text-semantic-info_base' },
    semanticSuccessLight: { text: 'text-semantic-success_light', bg: 'bg-semantic-success_light', textGroupHover: 'group-hover:text-semantic-success_light' },
    semanticSuccessBase: { text: 'text-semantic-success_base', bg: 'bg-semantic-success_base', textGroupHover: 'group-hover:text-semantic-success_base' },
    semanticWarningLight: { text: 'text-semantic-warning_light', bg: 'bg-semantic-warning_light', textGroupHover: 'group-hover:text-semantic-warning_light' },
    semanticWarningBase: { text: 'text-semantic-warning_base', bg: 'bg-semantic-warning_base', textGroupHover: 'group-hover:text-semantic-warning_base' },
    semanticWarningLightSecondary: { text: 'text-semantic-warning_light_secondary', bg: 'bg-semantic-warning_light_secondary', textGroupHover: 'group-hover:text-semantic-warning_light_secondary' },
    semanticWarningBaseSecondary: { text: 'text-semantic-warning_base_secondary', bg: 'bg-semantic-warning_base_secondary', textGroupHover: 'group-hover:text-semantic-warning_base_secondary' },
    semanticErrorLight: { text: 'text-semantic-error_light', bg: 'bg-semantic-error_light', textGroupHover: 'group-hover:text-semantic-error_light' },
    semanticErrorBase: { text: 'text-semantic-error_base', bg: 'bg-semantic-error_base', textGroupHover: 'group-hover:text-semantic-error_base' },

    // MDS Colors
    MDSLight: { text: 'text-semantic-trunks', bg: 'bg-semantic-trunks', textGroupHover: 'group-hover:text-semantic-trunks' },

    // Green Colors
    green50: { text: 'text-other-green-50', bg: 'bg-other-green-50', textGroupHover: 'group-hover:text-other-green-50' },
    green100: { text: 'text-other-green-100', bg: 'bg-other-green-100', textGroupHover: 'group-hover:text-other-green-100' },
    green200: { text: 'text-other-green-200', bg: 'bg-other-green-200', textGroupHover: 'group-hover:text-other-green-200' },
    green300: { text: 'text-other-green-300', bg: 'bg-other-green-300', textGroupHover: 'group-hover:text-other-green-300' },
    green400: { text: 'text-other-green-400', bg: 'bg-other-green-400', textGroupHover: 'group-hover:text-other-green-400' },

    // Pastel Colors
    pastel50: { text: 'text-other-pastel-50', bg: 'bg-other-pastel-50', textGroupHover: 'group-hover:text-other-pastel-50' },
    pastel100: { text: 'text-other-pastel-100', bg: 'bg-other-pastel-100', textGroupHover: 'group-hover:text-other-pastel-100' },
    pastel200: { text: 'text-other-pastel-200', bg: 'bg-other-pastel-200', textGroupHover: 'group-hover:text-other-pastel-200' },
    pastel300: { text: 'text-other-pastel-300', bg: 'bg-other-pastel-300', textGroupHover: 'group-hover:text-other-pastel-300' },
    pastel400: { text: 'text-other-pastel-400', bg: 'bg-other-pastel-400', textGroupHover: 'group-hover:text-other-pastel-400' },

    // Blue Colors
    blue50: { text: 'text-other-blue-50', bg: 'bg-other-blue-50', textGroupHover: 'group-hover:text-other-blue-50' },
    blue100: { text: 'text-other-blue-100', bg: 'bg-other-blue-100', textGroupHover: 'group-hover:text-other-blue-100' },
    blue200: { text: 'text-other-blue-200', bg: 'bg-other-blue-200', textGroupHover: 'group-hover:text-other-blue-200' },
    blue300: { text: 'text-other-blue-300', bg: 'bg-other-blue-300', textGroupHover: 'group-hover:text-other-blue-300' },
    blue400: { text: 'text-other-blue-400', bg: 'bg-other-blue-400', textGroupHover: 'group-hover:text-other-blue-400' },

    // Moodsand Colors
    moodsand50: { text: 'text-other-moodsand-50', bg: 'bg-other-moodsand-50', textGroupHover: 'group-hover:text-other-moodsand-50' },
    moodsand100: { text: 'text-other-moodsand-100', bg: 'bg-other-moodsand-100', textGroupHover: 'group-hover:text-other-moodsand-100' },
    moodsand200: { text: 'text-other-moodsand-200', bg: 'bg-other-moodsand-200', textGroupHover: 'group-hover:text-other-moodsand-200' },
    moodsand300: { text: 'text-other-moodsand-300', bg: 'bg-other-moodsand-300', textGroupHover: 'group-hover:text-other-moodsand-300' },
    moodsand400: { text: 'text-other-moodsand-400', bg: 'bg-other-moodsand-400', textGroupHover: 'group-hover:text-other-moodsand-400' },

    // Red Color
    red500: { text: 'text-other-red-500', bg: 'bg-other-red-500', textGroupHover: 'group-hover:text-other-red-500' },

    // Others Gray color
    otherGray500: { text: 'text-other-gray-500', bg: 'bg-other-gray-500', textGroupHover: 'group-hover:text-other-gray-500' },

    // Yellow Colors
    yellow100: { text: 'text-other-yellow-100', bg: 'bg-other-yellow-100', textGroupHover: 'group-hover:text-other-yellow-100' },
    yellow500: { text: 'text-other-yellow-500', bg: 'bg-other-yellow-500', textGroupHover: 'group-hover:text-other-yellow-500' },

    // Cerulean color
    cerulean500: { text: 'text-other-cerulean-500', bg: 'bg-other-cerulean-500', textGroupHover: 'group-hover:text-other-cerulean-500' },

    // Purple color
    purple500: { text: 'text-other-purple-500', bg: 'bg-other-purple-500', textGroupHover: 'group-hover:text-other-purple-500' },

    // Social colors
    facebook: { text: 'text-other-facebook', bg: 'bg-other-facebook', textGroupHover: 'group-hover:text-other-facebook' },
    twitter: { text: 'text-other-twitter', bg: 'bg-other-twitter', textGroupHover: 'group-hover:text-other-twitter' },
    linkedin: { text: 'text-other-linkedin', bg: 'bg-other-linkedin', textGroupHover: 'group-hover:text-other-linkedin' },
    youtube: { text: 'text-other-youtube', bg: 'bg-other-youtube', textGroupHover: 'group-hover:text-other-youtube' },
    lightBlue: { text: 'text-other-lightBlue', bg: 'bg-other-lightBlue', textGroupHover: 'group-hover:text-other-lightBlue' },

    // Pink Color
    pink900: { text: 'text-other-pink-900', bg: 'bg-other-pink-900', textGroupHover: 'group-hover:text-other-pink-900' },
}

export const S = {
    FONT_SIZE,
    CLR,
}