import React, { useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import ValidateUserInput from '../../components/common/ValidateUserInput';

const Textarea = ({  mandatory, characterLimit, minimumLength, maximumLength, validationHandler, handleKeyPress, handlePaste, className, placeholder, maxLength, children, props, register, error, disabled, id, isRequiredField,
    textareaRows, isEditable, variant = 'default', watch, dataAutomation, containerClassName, size, viewOnly, onChange, value, isLabelLine=false }) => {
    const resizeTextarea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    }

    const resizeRegularTextarea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = (e.target.scrollHeight) + 2 + 'px';
    }
    const { ref, ...rest } = register ? register(id) : {};
    const textareaRef = useRef(null);

    useEffect(() => {
        if (watch) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    }, [watch && watch(id)])

    const textarea = {
        simple: () => <textarea
            {...((register && !validationHandler) && { ...register(id, { required: isRequiredField }) })}
            {...((register && validationHandler) && { 
                ...register(id, { required: isRequiredField }),
                onChange: (event) => {
                    validationHandler?.onChange(event);
                    register(id, { required: isRequiredField })?.onChange(event);
                },
                onBlur: (event) => {
                    validationHandler?.onBlur(event);
                    register(id, { required: isRequiredField })?.onChange(event);
                },
            })}
            id={id}
            className={`${className} textareaSimple`}
            placeholder={placeholder}
            disabled={disabled}
            onInput={(e) => resizeTextarea(e)}
            rows={1}
            maxLength={maxLength}
            {...rest}
            ref={(e) => {
                ref(e)
                textareaRef.current = e
            }}
            {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-textarea-input') })}
            {...props}
        >
            {children}
        </textarea>,
        actionOnChange: () => <><textarea
            {...((register && !validationHandler) && { ...register(id, { required: isRequiredField }) })}
            {...((register && validationHandler) && { 
                ...register(id, { required: isRequiredField }),
                onChange: (event) => {
                    validationHandler?.onChange(event);
                    register(id, { required: isRequiredField })?.onChange(event);
                },
                onBlur: (event) => {
                    validationHandler?.onBlur(event);
                    register(id, { required: isRequiredField })?.onChange(event);
                },
            })}
            id={id}
            className={`${className} ${error && 'inputError'} ${viewOnly ? 'inputViewOnly' : disabled && 'inputDisabled'} inputDefault max-h-24 min-h-12`}
            placeholder={placeholder}
            disabled={viewOnly || disabled}
            onInput={resizeRegularTextarea}
            minLength={minimumLength}
            maxLength={maximumLength}
            {...(handleKeyPress && { "onKeyPress": event => handleKeyPress(event) })}
            {...((!register && validationHandler) && validationHandler)}
            {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-textarea-input') })}
            {...((onChange && !validationHandler) && { onChange: onChange })}
            value={value}
            {...props}
        >
            {children}
        </textarea>
        {placeholder && <label htmlFor={id}>{placeholder}</label>}</>,
        default: () => <>
            <textarea
                {...((register && !validationHandler) && { ...register(id, { required: isRequiredField }) })}
                {...((register && validationHandler) && { 
                    ...register(id, { required: isRequiredField }),
                    onChange: (event) => {
                        validationHandler?.onChange(event);
                        register(id, { required: isRequiredField })?.onChange(event);
                    },
                    onBlur: (event) => {
                        validationHandler?.onBlur(event);
                        register(id, { required: isRequiredField })?.onChange(event);
                    },
                })}
                id={id}
                className={`${className} ${error && 'inputError'} ${viewOnly ? 'inputViewOnly' : disabled && 'inputDisabled'} ${isLabelLine ? '' : ''} inputDefault max-h-24 min-h-12`}
                rows={textareaRows}
                placeholder={' '}
                disabled={viewOnly || disabled}
                onInput={resizeRegularTextarea}
                minLength={minimumLength}
                maxLength={maximumLength}
                {...(handleKeyPress && { "onKeyPress": event => handleKeyPress(event) })}
                {...((!register && validationHandler) && validationHandler)}
                {...((onChange && !validationHandler) && { onChange: onChange })}
                {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-textarea-input') })}
                {...props}
            >
                {children}
            </textarea>
            {placeholder && <label htmlFor={id}>{placeholder}</label>}
        </>
    }
    return (
        <div className={`${containerClassName} w-full inputContainer relative ${size == 'small' ? 'inputSizeSmall' : ''}`}>
            {
                textarea[variant]()
            }
            {error && (
                <div className="text-semantic-error_base text-sm mt-2 mb-14px">
                    {error.message}
                </div>
            )}

        </div>
    )
}

Textarea.defaultProps = {
    isRequiredField: false,
    textareaRows: 3
}
Textarea.propTypes = {
    register: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    isRequiredField: PropTypes.bool,
    isEditable: PropTypes.bool,
    /**
     * Apply height like rows
     */
    textareaRows: PropTypes.number,
    variant: PropTypes.oneOf(['simple', undefined]),
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ValidateUserInput(Textarea);