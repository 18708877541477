import * as HttpService from "./http.service";
import { CAPTURE_LEAD_NUTRITION, CAPTURE_LEAD_STATUS, CREATE_APPOINTMENT, CREATE_LEAD, CREATE_LEAD_APPOINTMENT, CREATE_PATIENT, GET_APPOINTMENT, GET_INDIVIDUAL_REQUEST_APPOINTMENT, GET_LEADS_DETAILS, GET_LEAD_CITIES, GET_LEAD_PATIENT_INFO, GET_MY_LEADS, MARK_LEAD_INFLIGHT, RESEND_LEAD_OTP, RESET_INFLIGHT_LEAD, UPDATE_LEAD_FOLLOW_UP_DATE, VERIFY_LEAD_OTP, CREATE_ORDER_LEAD_APPOINTMENT, PLACE_ORDER_V1, GET_APPOINTMENT_REQUEST_DETAILS, APPOINTMENT_REQUEST_AUTH } from "./url.service";

export const getMyLeads = ({ doctorId = '', offset, limit, search = '', sortType = '', patientMobile }) => {
    return HttpService.getWithOutAuth(GET_MY_LEADS({ doctorId, offset, limit, search, sortType, patientMobile }));
};


export const createPatient = (data) => {
    return HttpService.postWithAuth(CREATE_PATIENT(), data);
}

export const getAppointment = ({ doctorId, patientUserId, clinicId, leadId, actionType }) => {
    return HttpService.getWithOutAuth(GET_APPOINTMENT({ doctorId, patientUserId, clinicId, leadId, actionType }));
};


export const createLeadAppointment = (data) => {
    return HttpService.postWithAuth(CREATE_LEAD_APPOINTMENT(), data);
}

export const createLeadFollowUp = (appointmentId, data) => {
    return HttpService.postWithAuth(UPDATE_LEAD_FOLLOW_UP_DATE(appointmentId), data);
}

export const captureDietChart = (appointmentId, data) => {
    return HttpService.postWithAuth(CAPTURE_LEAD_NUTRITION(appointmentId), data);
}

export const captureLeadStatus = (mobile, data) => {
    return HttpService.postWithAuth(CAPTURE_LEAD_STATUS(mobile), data);
}

export const getLeadPatient = ({ doctorId, mobile, clinicId }) => {
    return HttpService.getWithOutAuth(GET_LEAD_PATIENT_INFO({ doctorId, mobile, clinicId }));
};


export const resetInflightLead = (data) => {
    return HttpService.postWithAuth(RESET_INFLIGHT_LEAD(), data);
}

export const markLeadInflight = (data) => {
    return HttpService.postWithAuth(MARK_LEAD_INFLIGHT(), data);
}

export const getLeadsDetails = (id) => {
    return HttpService.getWithAuth(GET_LEADS_DETAILS(id))
}

export const getLeadCities = () => {
    return HttpService.getWithOutAuth(GET_LEAD_CITIES())
}

export const createLead = (body) => {
    return HttpService.postWithOutAuth(CREATE_LEAD(), body)
}

export const verifyLeadOtp = (body) => {
    return HttpService.postWithOutAuth(VERIFY_LEAD_OTP(), body)
}

export const resendLeadOtp = ({ queryLeadId }) => {
    return HttpService.postWithOutAuth(RESEND_LEAD_OTP({ queryLeadId }))
}

export const getIndividualRequestAppointment = ({ offset, limit, search = '', sortType = '', patientMobile }) => {
    return HttpService.getWithToken(GET_INDIVIDUAL_REQUEST_APPOINTMENT({ offset, limit, search, sortType, patientMobile }))
}
export const createOrderLeadAppointment = (body) => {
    return HttpService.postWithAuth(CREATE_ORDER_LEAD_APPOINTMENT(), body)
}
export const updateOrderLeadAppointmentStatus = (body) => {
    return HttpService.putWithAuth(PLACE_ORDER_V1(), body)
}

export const getAppointmentRequestDetails = (params, token) => {
    return HttpService.getWithAuth(GET_APPOINTMENT_REQUEST_DETAILS(params), token)
}

export const appointment_request_auth = (body) => {
    return HttpService.postWithAuth(APPOINTMENT_REQUEST_AUTH(), body)
}