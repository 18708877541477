import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import SvgIcon from '../atoms/SvgIcon';
import Text from '../atoms/Text';
import { useSpring, animated, config } from 'react-spring'

const classes = {
    error: 'alert--error',
    error_v2: 'alert--error italic text-semantic-error_base font-semibold',
    success: 'alert--success',
    warning: 'alert--warning',
    warning_v2: 'alert--warning-v2',
    warning_v3: 'alert--warning-v2 px-0 py-0 border-none bg-transparent-0',
    slideUp: '',
    reminder: 'bg-semantic-error_dark text-gray-800 font-semibold'
}

const FontWeight = {
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold',
}

/**
## Important Info
Must put **`relative`** class in the parent element.
 * 
 */
const Alert = ({ className, type, children, resetAlertCallback, stayDuration, startPosition, endPosition, effect, fontWeight }) => {
    const [_startPosition, _setStartPosition] = useState(startPosition || (effect === 'slideUp' ? 100 : -100))
    const [_endPosition, _setEndPosition] = useState(endPosition || (effect === 'slideUp' ? 0 : 0))

    if (effect === 'withoutEffect') {
        return (
            <div className={`${className} ${classes[type]} ${type == 'error' ? 'text-semantic-error_base' : ''} ${FontWeight[fontWeight] || ''} alert relative block`}>
                {children}
            </div>
        )
    }

    const effectType = useMemo(() => {
        return {
            slideUp: {
                useSpring: {
                    from: { y: _startPosition },
                    to: { y: _endPosition },
                    config: { ...config.stiff },
                },
                class: "bottom-0 left-0"

            },
            slideDown: {
                useSpring: {
                    from: { y: _startPosition },
                    to: { y: _endPosition },
                    config: { ...config.stiff },
                },
                class: "top-0 left-0"
            },
        }
    }, [_startPosition, _endPosition])

    const props = useSpring(effectType[effect].useSpring)

    useEffect(() => {
        setTimeout(() => {
            _setEndPosition(_startPosition)

            setTimeout(() => {
                resetAlertCallback();
            }, 500);
        }, stayDuration * 1000);
    }, [stayDuration]);

    return (
        <animated.div style={props} className={`${className} ${classes[type]} ${effectType[effect].class} ${FontWeight[fontWeight] || ''} alert`} data-automation={`alert-for-${type === 'error' ? 'error' : 'success'}`}>
            <SvgIcon type={type} className="alert__icon w-8 fill-gray-900 hover:stroke-primary1-500" />
            <div className='ml-2 flex-grow text-left' data-automation='alert-text'>{children}</div>
        </animated.div>
    )
}
Alert.defaultProps = {
    stayDuration: 5,
}

Alert.propTypes = {
    className: PropTypes.string,
    /**
     * Which type should the Alert be?
     */
    type: PropTypes.oneOf(['error', 'success', 'warning', 'warning_v2', 'reminder']).isRequired,
    effect: PropTypes.oneOf(['slideDown', 'slideUp', 'withoutEffect']),
    /**
     * Alert content
     */
    children: PropTypes.string.isRequired,
    /**
     * How long does the poster have to be shown in seconds.
     */
    stayDuration: PropTypes.number,
    startPosition: PropTypes.number,
    endPosition: PropTypes.number
}

export default Alert