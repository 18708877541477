import React from 'react'
import PropTypes from 'prop-types'

import Avatar from './../../atoms/Avatar'
import IconCheckThick from 'atoms/SvgIcons/IconCheckThick.svg'

const Checkbox = ({ className, label, index, id, checked, handlerCheckboxChange, avatar, isLabel, isDisabled, labelActiveColor, dataAutomation, payload, medCheckbox = false}) => {
    const Type = isLabel ? 'label' : 'div';
    // rounded-full bg-primary1-500
    return (
        <div className={`${className} select-none flex`}>
            <Type className={`${isDisabled ? 'checkbox--disabled' : ''} cursor-pointer checkbox`}>
                <input hidden checked={checked} {...(!isDisabled && { onChange: () => handlerCheckboxChange({ checked: !checked, id, label, payload }) })}
                    type="checkbox" id={id} name="A3-confirmation" value={label} className={`${medCheckbox ? 'w-5 h-5' : 'w-6 h-6'} opacity-0 absolute cursor-pointer`} />
                <div className={`${medCheckbox ? 'w-5 h-5' : 'w-6 h-6'} rounded-sm-0.5 overflow-hidden flex flex-shrink-0 justify-center items-center`} {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-checkbox') })}>
                    {(!isDisabled || (checked && isDisabled)) && <IconCheckThick className={`${isDisabled ? 'fill-neutral-6' : 'fill-basic-white'} w-5 `} />}
                </div>
                {avatar && <Avatar className={`${medCheckbox ? 'w-5 h-5' : 'w-6 h-6'} rounded-full ml-3`} src={avatar} />}
                {label && <span className={`${(labelActiveColor === 'highLight' && checked) ? 'text-primary1-500' : 'text-gray-900'} 
                pl-2 select-none text-14-18`}>{label}</span>}
            </Type>
        </div>
    )
}

Checkbox.defaultProps = {
    checked: false,
    className: '',
    isLabel: true,
    isDisabled: false,
    labelActiveColor: 'default'
}

Checkbox.propTypes = {
    /**
     * Apply you classes
     */
    className: PropTypes.string,
    /**
     * Label for checkbox
     */
    label: PropTypes.string,
    /**
     * Id for checkbox
     */
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    /**
     * To keep check and uncheck
     */
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,

    labelActiveColor: PropTypes.oneOf(['default', 'highLight']),

}

export default Checkbox